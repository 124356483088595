import React from "react";
import { Link, withPrefix } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const SingleOffer = () => {
	const lang = "pl";

	return (
		<Layout
			lang={lang}
			seoTitle="Paleta 4.0"
			translationEN="/en/our-services/pallet-4-0/"
			translationDE="/de/angebot/palette-4-0/"
			isPallet4
		>
			<SmallBanner
				title="Paleta 4.0"
				aditionalBreadcrump="Oferta"
				aditionalBreadcrumpLink="/oferta/"
			/>

			<section className="pallet4-breaking">
				<div className="container">
					<h2 className="color--main">
						Przełomowy projekt na rynku logistycznym
					</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								<strong>
									Paleta 4.0 inaczej Inteligentna Paleta to
									nowy produkt
								</strong>
								, który poza podstawową funkcjonalnością
								związaną z przewozem i magazynowaniem towarów,
								jest również nośnikiem informacji, układem
								sensorycznym, węzłem wymiany danych
								logistycznych. Paleta 4.0 jest produktem o dużej
								skalowalności i wielu możliwych wariantach
								konfiguracji.
							</p>
							<img
								className="pallet4-breaking__img--1"
								src={require("../../assets/img/pallet4-content-image-1.png")}
								alt=""
							/>
							<p>
								<strong>
									Paleta 4.0 wpisuje się w nowe idee Przemysłu
									4.0 (Industry 4.0), Internetu Rzeczy (IoT)
								</strong>
								, wprowadza nowe narzędzia podnoszące
								bezpieczeństwo, kontrolę nad procesem
								logistycznym. Jest rozwiązaniem wspieranym przez
								nowoczesne platformy zarządzania logistycznego.
							</p>
						</div>
						<div className="col-lg-4">
							<img
								className="pallet4-breaking__img--2"
								src={require("../../assets/img/pallet4-content-image-2.png")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-what">
				<div className="container">
					<h2 className="color--main">Czym jest Paleta 4.0?</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								<strong>
									Wykonana z drewna Paleta 4.0 zbijana jest w
									nowoczesnej, w pełni kontrolowanej linii
									technologicznej
								</strong>
								. Technologia ta zapewnia większą niezawodność
								oraz odporność mechaniczną palet.{" "}
								<strong>
									Wewnątrz palety wbudowane są układy
									elektroniczne
								</strong>
								realizujące między innymi następujące funkcje:
							</p>

							<div className="row">
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>układy sensoryczne</h4>
										<p>
											odpowiedzialne za pomiary warunków
											środowiskowych
										</p>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>układy identyfikacji produktu</h4>
										<p>
											wraz z chronionym patentem system
											kodowania numeru seryjnego
										</p>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>układy komunikacji radiowej</h4>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="pallet4-what__item">
										<h4>
											układy
											<br /> zasilania
										</h4>
										<p>
											z wykorzystaniem technologii Energy
											harvesting
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-specs">
				<div className="container">
					<h2 className="color--main">Charakterystyka</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<p>
								Dzięki zastosowaniu innowacyjnych urządzeń
								elektronicznych, Paleta 4.0 komunikuje się z
								urządzeniami zewnętrznymi np. telefonami, dzięki
								czemu możliwe jest między innymi:
							</p>
							<ul>
								<li>
									identyfikowanie numeru seryjnego palety,
									numeru referencyjnego listu przewozowego,
									historii eksploatacyjnej, formy i
									przynależności własnościowej. W powiązaniu z
									systemami zarządzania logistycznego,
									następuje wymiana danych dotycząca procesu
									logistycznego,
								</li>
								<li>
									śledzenie brzegowych warunków środowiskowych
									takich jak:
								</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../assets/img/pallet4-content-image-3.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<div className="row">
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../assets/img/pallet4-content-ico-1.svg")}
												alt=""
											/>
										</div>
										<p>temperatura</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../assets/img/pallet4-content-ico-2.svg")}
												alt=""
											/>
										</div>
										<p>wilgotność</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../assets/img/pallet4-content-ico-3.svg")}
												alt=""
											/>
										</div>
										<p>masa towaru</p>
									</div>
								</div>
								<div className="col-xl-3"></div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../assets/img/pallet4-content-ico-4.svg")}
												alt=""
											/>
										</div>
										<p>urazy mechaniczne, uderzenia</p>
									</div>
								</div>
								<div className="col-xl-3 col-md-4">
									<div className="pallet4-specs__item">
										<div>
											<img
												src={require("../../assets/img/pallet4-content-ico-5.svg")}
												alt=""
											/>
										</div>
										<p>pochylenie palety</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />
					<br />

					<div className="col-lg-8 offset-lg-4">
						<p>
							Radiowy system identyfikacji jest niezbędny w
							autonomicznych magazynach wysokiego składowania.
							Szybki odczyt danych przez urządzania transportowe,
							odporność znaczników na uszkodzenia mechaniczne oraz
							duża pojemność informacyjna pozwalają na niezawodne
							procesy magazynowe w magazynach obsługiwanych przez
							roboty.
						</p>
						<p>
							<strong>
								Paleta 4.0 charakteryzuje się również zwiększoną
								wytrzymałością
							</strong>
							, co znacząco większa liczbę cykli załadunkowych
							oraz ogranicza potencjalne straty wynikające z
							awaryjności zwykłych palet.
						</p>
					</div>
				</div>
			</section>

			<section className="pallet4-construction">
				<div className="container">
					<h2 className="color--main">Budowa</h2>
					<div className="row">
						<div className="col-lg-8 offset-lg-4">
							<h4>
								Paleta 4.0 może składać się z kilku elementów:
							</h4>
							<ul>
								<li>drewnianej palety,</li>
								<li>
									wytrzymałych łączników, aplikowanych w
									nowoczesnej linii technologicznej,
								</li>
								<li>
									układów elektronicznych zamontowanych w
									wewnątrz elementów palety,
								</li>
								<li>
									przenośnych układów komunikacji radiowej
									(smartfon, czytnik RFID UHF),
								</li>
								<li>stacjonarnych układów odczytu,</li>
								<li>
									<strong>
										otwartej aplikacji do obsługi wraz z
										publicznymi bibliotekami (API),
									</strong>
								</li>
								<li>
									dedykowanych integratorów systemów
									logistycznych.
								</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../assets/img/pallet4-content-image-4.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<h4>Drewniana paleta:</h4>
							<p>
								Dzięki dokładności montażu oraz odpowiednim
								materiałom uzyskano:
							</p>
							<ul>
								<li>
									<strong>wysoką wytrzymałość</strong> (ok.
									30% większą niż dostępne aktualnie palety),
								</li>
								<li>
									<strong>dłuższą żywotność</strong> (dzięki
									lepszej neutralizacji naprężeń),
								</li>
								<li>
									<strong>większą stabilność palety</strong>{" "}
									(za sprawą ścisłego dopasowania elementów
									składowych palety poprzez odpowiedni
									montaż),
								</li>
								<li>
									<strong>
										jednorodność parametrów palet
									</strong>{" "}
									(dzięki zastosowaniu szczegółowej kontroli
									technicznej).{" "}
								</li>
							</ul>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4">
							<img
								src={require("../../assets/img/pallet4-content-image-5.png")}
								alt=""
							/>
						</div>
						<div className="col-lg-8">
							<h4>Elektronika składa się z:</h4>
							<ul>
								<li>
									<strong>platformy mikroprocesorowej</strong>{" "}
									(unikalny charakter potwierdzony w procesie
									patentowym, która charakteryzuje się bardzo
									dużą elastycznością),
								</li>
								<li>
									<strong>systemu komunikacyjnego:</strong>
								</li>
								<ul>
									<li>
										warstwa sprzętowa (wykorzystywany
										protokół BLE między innymi ze względu na
										niskie zużycie energii),
									</li>
									<li>
										warstwa programowa (wykorzystywany
										standard OPC UA, dzięki któremu istnieje
										możliwość sparowania z systemami ERP
										oraz MES).{" "}
									</li>
								</ul>
							</ul>

							<br />
							<br />
							<p>
								Warto zwrócić uwagę na fakt, że aktualnie na
								rynku diagnozuje się bardzo dużą nieprecyzyjność
								montażu palet będących w ofercie.{" "}
								<strong>
									Paleta 4.0 powinna być użyta w co najmniej 4
									cyklach załadunkowych
								</strong>
								, który jednorazowo obejmuje nałożenie towaru na
								paletę, załadunek z towarem na samochód oraz
								rozładunek.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-mobile">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<h2 className="color--white">Aplikacja mobilna</h2>
						</div>
						<div className="col-lg-8">
							<div className="row align-items-end">
								<div className="col-md-9">
									<p>
										<strong>
											Umożliwia bezpośredni odczyt danych
											z palety, dzięki czemu{" "}
											<span>
												nie ma konieczności zakupu
												dedykowanych urządzeń
											</span>
											.
										</strong>
									</p>
									<p>
										Udostępnione protokoły komunikacji
										umożliwiają samodzielne rozwijanie
										produktu, dostosowywanie formatu danych
										do posiadanych systemów oraz dowolna
										integracje z platformami logistycznymi.
									</p>
								</div>
								<div className="col-md-3">
									<img
										src={require("../../assets/img/pallet4-content-image-mobile-app.png")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pallet4-contact">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<h2 className="color--main">Masz pytania?</h2>
							<p>
								Jesteś zainteresowany
								<br /> naszym produktem?
							</p>
						</div>
						<div className="col-lg-8 offset-lg-1">
							<img
								src={require("../../assets/img/pallet4-content-image-contact.png")}
								alt=""
							/>
							<div className="pallet4-contact__row">
								<div>
									<Link
										to="/kontakt/"
										className="btn btn--white"
										data-hover="Skontaktuj się z nami"
									>
										Skontaktuj się z nami
										<span className="btn-arrows"></span>
									</Link>
								</div>
								<div>
									<a
										href={withPrefix(
											"/PalettenWerk_paleta_4-0_ulotka_a4_www_PL.pdf"
										)}
										target="_blank"
										rel="noreferrer"
										className="btn"
										data-hover="Pobierz ulotkę"
									>
										Pobierz ulotkę
										<span className="btn-arrows"></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default SingleOffer;
